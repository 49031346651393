@import './variables.scss';

html {
    overflow-x: hidden;
    height: 100%;
    touch-action: manipulation;
    -ms-overflow-style: none;
}

body {
    font-family: 'Open Sans', sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #6b7379;
    -webkit-font-smoothing: antialiased;
    line-height: 1.4;
    height: 100%;
    background-color: #fafafa;
    overflow: visible;
}

.btn,
button {
    outline: none !important;
}

/* Hide placeholder when focused */
input:focus::placeholder {
    color: transparent;
}
textarea:focus::placeholder {
    color: transparent;
}

.plain-button {
    border: none;
    margin: 0;
    overflow: visible;
    appearance: none;
}

.print-layout-buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
}

.print-layout-button {
    margin: 15px;
    border-radius: $plot-default-border-radius;
    background-color: #4d5558;
}

.print-layout-button:hover {
    svg path,
    svg line {
        stroke: white !important;
    }
    background-color: $plot-green;
}

.print-layout-button.selected {
    border: $plot-green 3px solid;
}

/* Hide google's automatic recaptcha badge. It collides with the intercom chat */
.grecaptcha-badge {
    display: none;
}

.application__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    z-index: 9;
    /*background-color: red;*/
}

.application__header {
    position: fixed;
    top: 0;
    height: 80px;
    left: 0;
    right: 0;
    z-index: 9999;
    background: #1f2725;
}

.application__body {
    margin-top: 80px;
}

.authenticate .application__body,
.authenticatesignin .application__body,
.authenticateforgot .application__body {
    top: 0;
}

div[class*='authenticateprovider'] .application__header,
div[class*='authenticatecoupon'] .application__header {
    top: 0;
}

.sn {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.us-all {
    -webkit-touch-callout: all;
    -webkit-user-select: all;
    -khtml-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;
}

.app {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
}

.fill {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.notification {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: black;
    opacity: 0.75;
    color: #fff;
    z-index: 9;
}

.centerize {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.loading {
    position: absolute;
    z-index: 99;
    top: 45%;
    left: 45%;
    width: 16px;
    height: 16px;
    background: transparent url(../images/ajax-loader.gif) no-repeat;
    background-size: contain;
    display: inline-block;
}

.plus {
    width: 40px;
    height: 40px;
    background: transparent url(../images/plus.png) no-repeat;
    background-size: contain;
    display: inline-block;
}

.close {
    width: 16px;
    height: 16px;
    background: transparent url(../images/close.png) no-repeat;
    background-size: contain;
    display: inline-block;
}

.close-modal-button {
    outline: none;
    background: transparent url(../images/close.png) no-repeat;
}

.close-modal-button {
    color: #fff;
    outline: none;
}
.close-modal-button.close-modal {
    @media only screen and (max-width: 480px) {
        right: 5px !important;
        top: 10px !important;
    }
}

.logo {
    width: 50px;
    height: 50px;
    background: transparent url(../images/plot-logo-square-250px.png) no-repeat;
    background-size: cover;
    display: inline-block;
    @media only screen and (min-width: 480px) {
        height: 46px;
        width: 126px;
        background: transparent url(../images/plot-logo-wide-light-250px.png) no-repeat;
        background-size: cover;
        display: inline-block;
        @media only screen and (min-width: 480px) {
            height: 46px;
            width: 126px;
            background: transparent url(../images/plot-logo-wide-light-250px.png) no-repeat;
            background-size: cover;
        }
    }
}

// Hide header on auth pages
.authenticate .application__header,
.authenticatesignin .application__header,
.authenticateforgot .application__header {
    display: none;
}

div[class*='authenticateprovider'] .application__header,
div[class*='authenticatecoupon'] .application__header {
    display: none;
}

.application__header .header-container {
    display: flex;
    height: 80px;
}

.application__header .header-meta-container {
    display: flex;
    align-items: center;
    @media only screen and (min-width: 768px) {
        justify-content: space-between;
        flex-grow: 1;
    }
}

.application__header .logo-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
    @media only screen and (min-width: 1025px) {
        margin-right: 45px;
    }
}

.application__header .header-menu-container {
    background-color: #1f2725;
    position: absolute;
    width: 100%;
    z-index: 10000;
    padding: 25px;
    justify-content: center;
    align-items: flex-end;
}

.application__header .header-menu--open {
    display: flex;
}

.application__header .header-menu-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 3px;
    flex-grow: 1;

    button {
        border: none;
        background: transparent;
    }

    @media only screen and (min-width: 768px) {
        display: none;
    }
}

.application__header .header-menu-links {
    display: flex;
    flex-direction: column;
}

.application__header .menu-toggle {
    cursor: pointer;
    border: none;
    outline: 0;
    background-size: 22px;
    background-repeat: no-repeat;
    display: block;
}

.lock {
    width: 16px;
    height: 16px;
    background: transparent url(../images/lock.png) no-repeat;
    background-size: contain;
    display: inline-block;
}

.mail {
    width: 16px;
    height: 16px;
    background: transparent url(../images/mail.png) no-repeat;
    background-size: contain;
    display: inline-block;
}

.script-icon {
    /*  margin-top: 4px;
  width: 12px;
  height: 12px;*/
    width: 15px;
    height: 15px;
    margin-top: 7px;
    background: transparent url(../images/script-icon.png) no-repeat;
    background-size: contain;
    display: inline-block;
}

.script-icon.active {
    width: 15px;
    height: 15px;
    margin-top: 7px;
    background: transparent url(../images/script-active-icon.png) no-repeat;
    background-size: contain;
    display: inline-block;
}

.images-icon {
    margin-top: 7px;
    width: 17px;
    height: 17px;
    background: transparent url(../images/images-icon.png) no-repeat;
    background-size: contain;
    display: inline-block;
}

.images-icon.active {
    width: 17px;
    height: 17px;
    background: transparent url(../images/images-active-icon.png) no-repeat;
    background-size: contain;
    display: inline-block;
}

.comments-icon,
.toolbar-comments-icon {
    margin-top: -7px;
    margin-right: -14px;
    width: 17px;
    height: 17px;
    background: transparent url(../images/comments-icon.png) no-repeat;
    background-size: contain;
    display: inline-block;
}

.toolbar-comments-icon {
    margin-top: 0;
    margin-right: 0;
    margin-top: 7px;
}

.comments-icon.active {
    width: 17px;
    height: 17px;
    background: transparent url(../images/comments-active-icon.png) no-repeat;
    background-size: contain;
    display: inline-block;
}

.comment {
    cursor: pointer;
}

.comment.reply {
    padding-left: 20px;
}

.hightlight-comment {
    background-color: #f0f0f0;
    .sb-avatar > div {
        background-color: $plot-green !important;
        color: #fff !important;
    }
}

.hightlight-comment:hover {
    background-color: #f0f0f0;
}

.login-input:focus {
    border: 1px solid #ccc !important;
}

.vcc {
    display: table;
    width: 100%;
    height: 100%;
}

.vc {
    display: table-cell;
    vertical-align: middle;
}

.drtl {
    direction: rtl !important;
}

.m-l--180 {
    margin-left: -180px !important;
    top: -30;
}

.header-right-menu {
    list-style: none;

    span:hover {
        cursor: pointer;
    }
}

.header-menu-container .header-right-menu {
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.header-meta-container .header-right-menu {
    display: none;
    @media only screen and (min-width: 768px) {
        display: flex;
        margin-left: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 8px;
    }
}

.header-right-menu > li {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;

    a:focus {
        outline: none;
    }
}

.header-right-menu .topMenuItem {
    color: rgb(153, 153, 153);
    text-decoration: none;
}

.header-right-menu .topMenuItem:hover {
    color: $plot-green;
    text-decoration: none;
}

.r3 {
    border-radius: 3px 3px 3px 3px;
    -moz-border-radius: 3px 3px 3px 3px;
    -webkit-border-radius: 3px 3px 3px 3px;
    border: 0px solid #ddd;
}

.Project__container:hover .projectItem {
    -webkit-box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
}

.projectItem .overlay {
    display: block;
    background-color: black;
    opacity: 0.65;
}

.locked-scene {
    display: block;
    background-color: white;
    opacity: 0.65;
    z-index: 1;
}

.locked-project {
    margin: 0 15px;
    display: block;
    background-color: white;
    opacity: 0.75;
}

.lock-wrapper {
    padding-top: 56.25%;
    top: 0;
    right: 50%;
    z-index: 2;
}

.lock-icon {
    display: block;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 30px;
    border-radius: 10px;
    border: $plot-black solid 6px;
    background: $plot-black url(../images/lock-green.png) no-repeat center/100%;
    box-shadow: 0 0 20px $plot-black;
}

.projectItem .curtain {
    display: none;
}

.Project__container:hover .curtain {
    display: block;
}

.curtain .content {
    z-index: 99;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.nailthumb {
    display: block;
}

.nailthumb img {
    object-fit: contain;
    height: 100% !important;
    width: 100%;
}

.nailthumb img.portrait {
    width: 100%;
    height: auto;
}

.viewBtn {
    cursor: pointer;
    border: 1px solid #fff;
    padding: 5px 15px;
    background-color: transparent;
    color: #fff;

    &:hover {
        color: #fff;
    }
}

.newBtnInner:hover {
    border: 2px dashed $plot-green !important;
    cursor: pointer;
    background-color: #eff9f6 !important;
}

.Project__container .project__container-details-owner {
    color: #999;
}

.actionBtns {
    display: none !important;
}

.Project__container:hover .actionBtns {
    display: flex !important;
}

.actionBtns .invite:hover,
.actionBtns .print:hover {
    color: $plot-green !important;
    cursor: pointer !important;
}

.actionBtns .trash:hover {
    color: $plot-red !important;
    cursor: pointer !important;
}

.tooltipSuccess {
    background-color: $plot-green !important;
}

.tooltipSuccess .place-top,
.tooltipSuccess:after {
    border-top-color: $plot-green !important;
    border-top-style: $plot-green !important;
    border-top-width: 6px !important;
}

img {
    -webkit-transform: translate3d(0, 0, 0);
}

.va {
    display: flex;
    flex: 1;
    align-items: center;
}

.h100 {
    height: 100%;
}

.h15 {
    height: 15px;
}

.h50 {
    height: 50%;
}

.w100 {
    width: 100%;
}

.w80 {
    width: 80%;
}

.w60 {
    width: 60%;
}

.w50 {
    width: 50%;
}

.w25 {
    width: 25%;
}

.aife {
    align-items: flex-end;
}

.aifs {
    align-items: flex-start;
}

.aic {
    align-items: center;
}

.jcfe {
    justify-content: flex-end;
}

.jcfs {
    justify-content: flex-start;
}

.jcc {
    justify-content: center;
}

.btn {
    font-family: 'Open Sans', sans-serif, Helvetica, Arial;
}

.btn .caret {
    margin-left: 10px;
}

.bg-white {
    background-color: #fff;
    color: #6b7379;
}

.bg-white a {
    color: #545a5f;
}

.bg-white a:hover {
    color: #3c4144;
}

.bg-white .text-muted {
    color: #939aa0 !important;
}

.bg-white-only {
    background-color: #fff;
}

.bg-empty {
    background-color: transparent;
}

.b {
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.b-a {
    border: $default-toolbar-scene-border;
}

.b-t {
    border-top: $default-toolbar-scene-border;
}

.b-r {
    border-right: $default-toolbar-scene-border;
}

.b-b {
    border-bottom: $default-toolbar-scene-border;
}

.b-l {
    border-left: $default-toolbar-scene-border;
}

.b-light {
    border-color: #d9e4e6;
}

.b-dark {
    border-color: #657789;
}

.b-primary {
    border-color: rgb(10, 223, 166);
}

.b-success {
    border-color: #1dcc74;
}

.b-info {
    border-color: #60bed1;
}

.b-warning {
    border-color: #fbdc4c;
}

.b-danger {
    border-color: #f26767;
}

.b-black {
    border-color: #2e3941;
}

.b-white {
    border-color: #fff;
}

.b-dashed {
    border-style: dashed !important;
}

.b-2x {
    border-width: 2px;
}

.b-3x {
    border-width: 3px;
}

.b-4x {
    border-width: 4px;
}

.b-5x {
    border-width: 5px;
}

.r {
    border-radius: 2px 2px 2px 2px;
}

.r-2x {
    border-radius: 4px;
}

.r-3x {
    border-radius: 6px;
}

.r-l {
    border-radius: 2px 0 0 2px;
}

.r-r {
    border-radius: 0 2px 2px 0;
}

.r-t {
    border-radius: 2px 2px 0 0;
}

.r-b {
    border-radius: 0 0 2px 2px;
}

.pt-10 {
    padding-top: 10px;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
}

.pr {
    position: relative;
}

.clear {
    display: block;
    overflow: hidden;
}

.pos-rlt {
    position: relative;
}

.pos-stc {
    position: static;
}

.pos-abt {
    position: absolute;
}

.text-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex {
    display: flex;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.block {
    display: block;
}

.dn {
    display: none;
}

.font-thin {
    font-weight: 300;
}

.font-bold {
    font-weight: 700;
}

.text-lg {
    font-size: 18px;
}

.text-md {
    font-size: 16px;
}

.text-sm {
    font-size: 13px;
}

.text-xs {
    font-size: 12px;
}

.text-xxs {
    font-size: 10px;
}

.text-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-u-c {
    text-transform: uppercase;
}

.text-l-t {
    text-decoration: line-through;
}

.text-u-l {
    text-decoration: underline;
}

.text-active,
.active > .text,
.active > .auto .text {
    display: none !important;
}

.active > .text-active,
.active > .auto .text-active {
    display: inline-block !important;
}

.box-shadow {
    box-shadow: $plot-default-box-shadow;
}

.scene-box-shadow {
    box-shadow: $plot-scene-box-shadow;
}

.wrapper-xs {
    padding: 5px;
}

.wrapper-sm {
    padding: 10px;
}

.wrapper {
    padding: 15px;
}

.wrapper-md {
    padding: 20px;
}

.wrapper-lg {
    padding: 30px;
}

.wrapper-xl {
    padding: 50px;
}

.padder-lg {
    padding-left: 30px;
    padding-right: 30px;
}

.padder-md {
    padding-left: 20px;
    padding-right: 20px;
}

.padder {
    padding-left: 15px;
    padding-right: 15px;
}

.padder-v {
    padding-top: 15px;
    padding-bottom: 15px;
}

.no-padder {
    padding: 0 !important;
}

.pull-in {
    margin-left: -15px;
    margin-right: -15px;
}

.pull-out {
    margin: -10px -15px;
}

.scene-description:focus {
    color: #000;
}

.scene-description {
    overflow: hidden;
    background-color: white;
}

.gm-scrollbar.-vertical {
    width: 4px;
    top: 2px;
    display: none;
}

.text-area-container {
    top: 40px;
    bottom: 0;
    width: 100%;
    position: absolute;
    border: $default-toolbar-scene-border;
    border-top: none;
    padding: 0;
}

.text-area {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    textarea {
        min-height: 52px;
    }
}

.scene-rearrange-left {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    left: -2px;
}

.scene-rearrange-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    right: -3px;
}

.scene-dragging {
    border: 2px dashed rgb(10, 223, 166);
}

.pageBreak {
    page-break-after: always;
}

.pageBreakBefore {
    page-break-before: always;
}

.scene .scene-delete-icon {
    position: absolute;
    top: 10px;
    left: 0;
    display: none;
}

.scene:hover .scene-delete-icon {
    display: block;
}

@media only screen and (min-width: 768px) {
    .is-table-row {
        display: table;
    }
    .is-table-row [class*='col-'] {
        float: none;
        display: table-cell;
        vertical-align: top;
    }
}

.control-panel-tab {
    cursor: pointer;
    border-bottom: $default-toolbar-scene-border;

    &:hover {
        background-color: #eee;
    }
}

.control-panel-tab.active {
    /*background-color: rgb(10, 223, 166)!important;*/
    color: #fff !important;
    border-bottom: 2px solid rgb(10, 223, 166) !important;
}

.card-thumbnail {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 135px;
    margin-top: 20px;
    @media (max-width: 480px) {
        width: 100%;
        height: 170px;
    }
}

.card-thumbnail img {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.card-thumbnail img.portrait {
    width: 100%;
    height: auto;
}

.card-thumbnail-preloader {
    background-color: #fff;
}

.pos-rlt {
    position: relative;
}

.cursor-move {
    cursor: move;
}

.pos-stc {
    position: static;
}

.pos-abt {
    position: absolute;
}

.scene-image-dropzone {
    opacity: 0.5;
    background-color: transparent;
}

.scene-edit-active {
    opacity: 0.1;
}

.scene .imageloader {
    /*z-index: -1;*/
}

.op0 {
    opacity: 0;
}

.op1 {
    opacity: 1;
}

.little-opacity {
    opacity: 0.25;
}

.new-line {
    white-space: pre-line;
}

.info-tag {
    vertical-align: sub;
    background: $plot-green;
    padding: 0 5px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    margin-left: 5px;
    font-size: small;
    user-select: none;
}

.content-editable-textarea {
    white-space: pre-wrap;
    margin-right: 5px !important;
    margin-left: 5px !important;
    outline: none !important;
}

.outline-none {
    outline: none;
}

.border-none {
    border: none;
}

.resize-none {
    resize: none;
}

.comment-filter-search {
    text-align: center;
    background-color: #eee;
    height: 40px;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 20px;
}

.comments-header {
    height: 60px;
}

.scene textarea {
    min-width: 1px;
    display: inline-block;
    white-space: pre-wrap;
}

.scene__description textarea {
    min-width: 1px;
    display: block !important;
}

.scene__voice-over textarea {
    min-width: 1px;
    display: block !important;
}

textarea::placeholder {
    font-style: italic;
    font-weight: 300;
}

.dark-curtain {
    background-color: rgba(42, 45, 51, 0.8);

    &.modal-background {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
    }
}

.plot-modal {
    width: 100%;
    display: table;
    height: 100%;
    position: relative;
    z-index: 9999;

    .signup__desc {
        max-width: 600px;
        margin: auto;
    }
}

.vertical-center {
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
}

.cp {
    cursor: pointer;
}

@-webkit-keyframes slide {
    100% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
}

.slide:hover {
    -webkit-animation: slide 1s ease;
    animation: slide 1s ease;
}

.bg-green {
    background-color: green;
}

.bg-blue {
    background-color: blue;
}

.bg-red {
    background-color: red;
}

.bg-yelo {
    background-color: yellow;
}

.bg-magenta {
    background-color: magenta;
}

.h100px {
    height: 100px;
}

.scroll-y {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.project-header-container {
    display: none;
    position: absolute;
    padding: 15px;
    height: 60px;
    @media only screen and (min-width: 768px) {
        display: block;
    }
}

.toolbar {
    border: $default-toolbar-scene-border;
    border-bottom: none;
    top: 0;
    height: 40px;
    .control-panel-tab:not(:first-child) {
        border-left: $default-toolbar-scene-border;
        border-color: #f5f5f5;
    }
}

.toolbar__thumbnail__container {
    width: calc(50% - 10px);
    display: inline-block;
}

.toolbar__thumbnail {
    border: $default-toolbar-scene-border;
    margin: 20px 0px 0px 20px;
    &:hover {
        border-color: #cbccd2;
    }
}

.toolbar__thumbnail .imageloader {
    padding-bottom: 100%;
}

.toolbar__thumbnail {
    img {
        position: absolute;
        object-fit: cover;
        border-color: #f5f5f5;
    }
    &.canEdit {
        img {
            cursor: pointer;
        }
    }
}

.toolbar__thumbnail:hover .delete-image {
    display: block;
}

.toolbar__thumbnail:hover .delete-image:hover {
    color: rgb(10, 223, 166);
}

.toolbar-images {
    display: flex;
    flex-wrap: wrap;
}

.toolbar__thumbnail .imageloader.loading {
    all: unset;
}

.toolbar-container textarea {
    min-width: 2px;
    border: 1px solid transparent;
}

.toolbar-container > .container {
    padding: 0;
}

.projects__body > div::-webkit-scrollbar,
.toolbar-container::-webkit-scrollbar {
    display: none;
}

/* scene icons */

.dark-curtain .button-container {
    width: 100%;
}

.text-icon {
    color: $text-icon;
}

.scene .icon-link > span:first-child {
    display: block;
}

.scene .icon-link:hover > span:first-child {
    display: none;
}

.scene .icon-link:hover > span:last-child {
    display: block;
}

.scene-border,
.project-border {
    border: $default-toolbar-scene-border;
}

.scene-inner-container {
    @media only screen and (max-width: 1024px) {
        cursor: pointer;
    }
    cursor: grab;

    .dark-curtain {
        z-index: 2;
    }
}

.scene-action-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;

    .scene-action-left {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5px;
    }
    .scene-action-right {
        .scene-action-button {
            padding: 5px 5px 0 0;
        }
    }
}

.scene-action-button {
    padding: 5px 0 0 5px;
    &.active {
        .scene-action-button--icon {
            background: $plot-green;
        }
    }
    label {
        cursor: pointer;
    }
    input[id*='upload-scene-image__'] {
        display: none;
    }
}

.scene-action-button--icon {
    background: $plot-menu-hover-grey;
    border-radius: 0.2em;
    &:hover {
        background: $plot-green;
    }
    &.delete {
        &:hover {
            background: $plot-red;
        }
    }
}

.scene .image-container {
    // 16x9 aspect ratio
    padding-top: 56.25%;
    .thumb-text {
        top: 45%;
        text-align: center;
    }
    &.activateImageDZ {
        background: rgba(10, 223, 166, 0.25);
    }
}

.scene.dragging .image-container:hover .dark-curtain {
    display: none;
}

.inner-double-line {
    height: 7px;
    width: 18px;
    margin-top: 4px;
    border-top: 2px solid #bbb;
    border-bottom: 2px solid #bbb;
}

.drag-handler {
    display: none;
    bottom: -15px;
    height: 28px;
    position: absolute;
    cursor: move;
}

.scene:hover .drag-handler {
    display: block;
}

.icon-link.comment > span:first-child {
    width: 12px;
    height: 12px;
}

.icon-link.comment > span:last-child {
    width: 12px;
    height: 12px;
}

.project-meta {
    width: 40%;
    display: flex;
    align-self: flex-end;
    flex-direction: row;
    position: absolute;
    &.public {
        width: 50%;
        max-width: 100%;
    }
    @media only screen and (min-width: 768px) {
        max-width: 25%;
    }
    @media only screen and (min-width: 900px) {
        max-width: 40%;
    }
    @media only screen and (min-width: 1260px) {
        max-width: 70%;
    }
    .project-meta__info {
        display: flex;
        span:focus {
            outline: none;
        }
        .project-meta-title {
            align-self: flex-end;
            font-size: 16px;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 32px;
            padding-bottom: 10px;
            direction: ltr;
            &.editing {
                text-overflow: unset;
            }
        }
    }
}

.project-meta ul.dropdown-menu {
    top: 25px;
    left: 10px;
}

.project-meta button.dropdown-toggle {
    padding-left: 0;
    padding-right: 0;
}

.project-meta button.dropdown-toggle span.caret {
    color: #fff;
}

.project-meta span:first-child:focus {
    outline: none;
}

.scene .drag-cover {
    z-index: 9;
    opacity: 0.3;
}

.popover-title {
    display: none;
}

.popover-content {
    padding: 0;
}

.popover-content textarea {
    border-radius: $plot-default-border-radius;
    padding: 5px 10px;
}

#popover-positioned-right {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: white;
    border: 1px solid #eee;
    border-radius: $plot-default-border-radius;
    width: 260px;
    box-shadow: $plot-default-box-shadow;
    margin-top: -4px;
}

#popover-positioned-right .btn,
#popover-positioned-right .btn:hover {
    color: #fff;
}

.popover-content textarea:focus {
    border: 1px solid rgb(10, 223, 166);
}

.comment:hover {
    background-color: #fcfcfc;
}

.add-comment-button {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    width: 100%;
    background-color: #eee;
}

.comment-box-overlay {
    border: 1px solid #eee;
    box-shadow: $plot-default-box-shadow;
    padding: 10px;
    margin-top: 25px;
}

.comment .resolved {
    text-decoration: line-through;
    color: #ccc;
}

.comment .action-buttons {
    display: none;
}

.comment:hover .action-buttons {
    display: block;
}

.comment .action-buttons i {
    color: #ccc;
}

.comment .action-buttons i:hover {
    color: #666;
}

.scene-delete-dialog .btn {
    width: 70%;
}

.scene-delete-dialog .btn.bg-danger {
    color: #fff;
}

.scene-delete-dialog .btn.cancel {
    background-color: #6e7c78;
    color: #fff;
}

.inline-comment textarea:focus {
    border: 1px solid rgb(10, 223, 166);
}

.superFancyBlockquote {
    color: #999;
    font-family: 'Hoefler Text', Georgia, serif;
    font-style: italic;
    text-align: center;
}

.project-search {
    width: 100%;
    height: 40px;
    background-color: #eee;
    text-align: center;
    outline: none;
    border: none;
    font-size: 14px;
    border-radius: 20;
}

.Project__action-btns-left i,
.Project__action-btns-left {
    flex: 0.5;
    color: #aaa !important;
}

.Project__action-btns-right .invite-btn {
    color: #aaa;
}

.Project__action-btns-right i {
    color: #aaa;
}

.Project__action-btns-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.Project__action-btns-wrapper {
    position: absolute;
    width: 100%;
    margin-left: 15px;
    padding-right: 60px;
    bottom: 9px;
    display: flex;
    flex-direction: row;
    flex: 1;
}

.Project__container {
}

.Project__container-details {
    width: 100%;
    height: 78px;
    background-color: #fff;
    position: relative;
}

.Project__container-details-footer {
    font-size: 13px;
    color: #999;
    position: absolute;
    height: 36px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.Project__container-details-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 20px;
    padding: 10px;
}

.Project__thumb-container {
    width: 100%;
    // 16x9 aspect ratio
    padding-top: 56.25%;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.Project__scene-count {
    color: #999;
    font-size: 13px;
}

.Project__title {
    font-weight: 600;
    color: #000;
    white-space: nowrap;
    line-height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;

    @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}

.projects__header {
    position: absolute;
    height: 80px;
    left: 0;
    right: 0;
    top: 0;

    a.btn:focus {
        outline: none;
    }
    @media only screen and (min-width: 500px) {
        a {
            width: 50%;
        }
    }
}

.projects__body {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
}

.btn-submit {
    outline: none;
    color: #fff !important;
}

.btn-submit:not([disabled]):focus,
.btn-submit:not([disabled]):hover {
    background-color: $plot-green-hover;
    color: #fff;
}

.form-group input {
    outline: none !important;
}

.global-file-upload__backdrop {
    background-color: rgb(10, 223, 166);
    z-index: 999;
    opacity: 0.65;
}

.global-file-upload__content {
    z-index: 9999;
}

.global-file-upload__message {
    padding: 40px 60px;
    border-radius: 6px;
}

.scene-row__hack-child {
    position: absolute;
    left: -16px;
    width: 1px;
}

.scene__new-scene-wrpr:hover .scene_new-scene-icon {
    color: rgba(10, 223, 166, 0.5);
    animation: fadein 0.5s;
}

.project-create-dialog .icon > span {
    color: rgb(10, 223, 166);
}

.project-create-dialog .form-group,
.invite-dialog .form-group {
    margin-bottom: 10px;
    border-radius: $plot-default-border-radius;
}

.project-create-dialog .form-control:focus,
.invite-dialog .form-control:focus {
    border-color: inherit;
    outline: none;
}

.project-create-dialog input[type='text'].form-control,
.invite-dialog input[type='text'].form-control {
    height: 42px;
}

.project-create-dialog .description,
.invite-dialog .description {
    color: #fff;
}

.project-create-dialog .btn-submit,
.invite-dialog .btn-submit {
    height: 42px;
    border-radius: $plot-default-border-radius;
}

.project-create-dialog .close,
.invite-dialog .close {
    color: #ddd;
}

.project-create-dialog input,
.invite-dialog input {
    text-align: center;
    background-color: transparent;
    color: #fff;
}

.project-create-dialog .content,
.invite-dialog .content {
    width: 75%;
    margin: 0 auto;
}

.invite-dialog .members {
    margin-top: 20px;
    padding-top: 16px;
    border-top: 1px solid #666;
}

.invite-dialog .members .remove-column {
    width: 20px;
}

.invite-dialog .invite-free-user-message {
    margin-top: 15px;
    margin-bottom: 15px;
}

.invite-dialog .public-link .button-input {
    margin-bottom: 10px;
}

.b-2x-dashed {
    border: 2px dashed #eee;
}

.delete-image {
    display: none;
    color: #ddd;
    background: #646b73;
    border-radius: 0.2em;
    &:hover {
        background: $plot-red;
    }
}

/* LOADER */

.custom-loader {
    position: absolute;
}

.custom-loader-container {
    position: fixed;
    overflow-y: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: flex-start;
    z-index: 10000;
}

.custom-loader span {
    display: block;
    margin: 0 auto;
}

.custom-loader span[class*='l-'] {
    height: 4px;
    width: 4px;
    background: #000;
    display: inline-block;
    margin: 12px 2px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-animation: loader 4s infinite;
    -webkit-animation-timing-function: cubic-bezier(0.03, 0.615, 0.995, 0.415);
    -webkit-animation-fill-mode: both;
    -moz-animation: loader 4s infinite;
    -moz-animation-timing-function: cubic-bezier(0.03, 0.615, 0.995, 0.415);
    -moz-animation-fill-mode: both;
    -ms-animation: loader 4s infinite;
    -ms-animation-timing-function: cubic-bezier(0.03, 0.615, 0.995, 0.415);
    -ms-animation-fill-mode: both;
    animation: loader 4s infinite;
    animation-timing-function: cubic-bezier(0.03, 0.615, 0.995, 0.415);
    animation-fill-mode: both;
}

.custom-loader.inverse span[class*='l-'] {
    background: #eee;
}

.custom-loader span.l-1 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -ms-animation-delay: 1s;
    -moz-animation-delay: 0.5s;
}

.custom-loader span.l-2 {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    -ms-animation-delay: 0.8s;
    -moz-animation-delay: 0.4s;
}

.custom-loader span.l-3 {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
    -ms-animation-delay: 0.6s;
    -moz-animation-delay: 0.3s;
}

.custom-loader span.l-4 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    -ms-animation-delay: 0.4s;
    -moz-animation-delay: 0.2s;
}

.custom-loader span.l-5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    -ms-animation-delay: 0.2s;
    -moz-animation-delay: 0.1s;
}

.custom-loader span.l-6 {
    -webkit-animation-delay: 0;
    animation-delay: 0;
    -ms-animation-delay: 0;
    -moz-animation-delay: 0;
}

@-webkit-keyframes loader {
    0% {
        -webkit-transform: translateX(-30px);
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        -webkit-transform: translateX(30px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes loader {
    0% {
        -moz-transform: translateX(-30px);
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        -moz-transform: translateX(30px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-keyframes loader {
    0% {
        -transform: translateX(-30px);
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        -transform: translateX(30px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-ms-keyframes loader {
    0% {
        -ms-transform: translateX(-30px);
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        -ms-transform: translateX(30px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

/* Loader end */

.o50 {
    opacity: 0.5;
}

.box-shadow-z4,
.box-shadow-z4 .box,
.box-shadow-z4 .box-color {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26), 0 -1px 0 rgba(0, 0, 0, 0.02);
}

.box-shadow-z3,
.box-shadow-z3 .box,
.box-shadow-z3 .box-color {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.02);
}

.bg-green {
    background-color: $plot-green;
    color: white;
}

.plot-info-box {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 200px auto;
    max-width: 400px;
}

.plot-info-box__content {
    display: block;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
}

.projects__null-state {
    background-color: #2a2d33;
    margin: auto;
    position: relative;
    padding: 30px;
    border-radius: 5px;
    .projects__null-state-title {
        color: #fff;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 0;
    }
    .projects__null-state-desc {
        color: #fff;
    }
    .projects__null-state-action {
        margin-top: 20px;
        color: #0adfa6;
        text-transform: uppercase;
        font-style: italic;
        font-weight: bold;
        cursor: pointer;
    }
}

.upgrade__benefits_desc {
    width: 300px;
    margin: 30px auto;
}

.upgrade__benefits_list {
    width: 190px;
    margin: 20px auto;
    text-align: left;
    position: relative;
}

a,
.btn-link {
    color: #999;
    &:hover,
    &:focus {
        color: #0adfa6;
        text-decoration: none;
    }
}

.three {
    width: 33%;
}

.button-input {
    display: flex;
    width: 100%;
    height: 42px;

    input {
        height: 42px;
        width: 100%;
        border: 1px solid #ccc;
        color: #999;
        border-right: none;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        line-height: 1.42857143;
        font-size: 14px;
        padding: 6px 12px;
    }

    button {
        width: 40px;
        background-color: #0adfa6;
        border: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        &:hover {
            background-color: $plot-green-hover;
        }

        svg {
            width: 15px;
        }
    }
}

.or-hr {
    padding: 15px 0px;
    display: flex;
    hr {
        border-top: 1px solid #666;
        width: 50%;
    }
    span {
        color: #fff;
        font-size: 16px;
        font-style: italic;
        padding-top: 3px;
        text-align: center;
        width: 15%;
    }
}

.cover-image-thumb img {
    object-fit: contain;
    width: 100% !important;
    height: 100% !important;
}

// OVERWRITTEN STYLINGS
.css-hmbzfg:hover {
    background-color: rgba(211, 211, 211, 0.5) !important;
    margin: 20px;
}

.ikjtlD:hover {
    background-color: rgba(211, 211, 211, 0.5);
}

.popover.bottom > .arrow {
    display: none !important;
}

.comment-popover-modaltip {
    &.presentationComment {
        z-index: 99999;
    }
    z-index: 99;
    position: absolute;
    padding: 0;
    max-width: 80%;
    white-space: nowrap;
    border: none;
}

// Project dropdown

.application__header .project-header-dropdown {
    margin-left: 15px;
    margin-bottom: 8px;
}

.project-dropdown {
    position: relative;
    .project-dropdown__body-wrapper {
        animation: bounce 1s ease;
        display: inline-block;
        position: absolute;
        min-width: 150px;
        z-index: 1000;
    }
    &.project-filter-dropdown,
    &.project-member-dropdown {
        .project-dropdown__body-wrapper {
            left: 0;
            top: 100%;
            margin-top: 10px;
        }
    }
    &.project-header-dropdown {
        .project-dropdown__body-wrapper {
            margin-left: 10px;
            @media only screen and (max-width: 767px) {
                left: 0;
                top: 100%;
                margin-top: 8px;
                margin-left: 0;
                min-width: 115px;
            }
        }
    }
    @keyframes bounce {
        0% {
            transform: scale(1, 1) translateY(0);
        }
        10% {
            transform: scale(1, 1) translateY(-1px);
        }
        100% {
            transform: scale(1, 1) translateY(0);
        }
    }
}

.project-dropdown__body {
    background-color: #788188;
    overflow: hidden;
    border-radius: 0.25em;
    box-shadow: $plot-default-box-shadow;
    margin: 0;
    padding: 0;
    list-style: none;
}

.project-dropdown > button {
    cursor: pointer;
    border: none;
    border-radius: 0.2em;
    padding: 0.3em;
    &:focus {
        outline: none;
    }
}

.project-filter-dropdown__btn {
    background-color: transparent;
    color: #999;
    &:hover {
        color: rgb(10, 223, 166);
    }
}

.project-member-dropdown__btn {
    padding-left: 10px !important;
}

.project-header-dropdown__btn,
.project-member-dropdown__btn {
    background-color: $plot-menu-hover-grey;
    color: #fff;
    &.active {
        background-color: $text-icon;
    }
    &:hover {
        background-color: $text-icon;
    }
}

.verify-form {
    margin: 30px auto;
}

/*---------------------------------------------------------------
                  Upgrade Subscription
---------------------------------------------------------------*/

// .upgrade-subscription {
//   width: 100% !important;
//   margin-top: 15px !important;
//   display: flex;
//   flex-wrap: nowrap;
//   .subscription-profile {
//     margin-right: 25px;
//     .subscription-plan,
//     .subscription-type {
//       display: flex;
//     }
//     .subscription-plan {
//       flex-direction: row;
//       .subscription-plan__section {
//         position: relative;
//         &:not(:first-child) {
//           padding-left: 15px;
//         }
//         button {
//           background: #383e3e;
//           font-weight: bold;
//           padding: 10px 20px;
//           border-color: transparent;
//           margin-top: 5px;
//           text-transform: capitalize;
//         }
//         .subscription-plan__text {
//           font-weight: bold;
//           text-align: center;
//           font-style: italic;
//           text-transform: uppercase;
//           position: absolute;
//           top: -15px;
//           left: 0;
//           right: 0;
//         }
//         &.active {
//           .subscription-plan__text {
//             color: $plot-green;
//           }
//           button {
//             background: $plot-green;
//           }
//         }
//       }
//     }
//     .subscription-type {
//       flex-direction: column;
//       .subscription-type__section {
//         padding: 25px;
//         border: 2px solid #686f75;
//         margin-top: 15px;
//         text-align: center;
//         border-radius: 4px;
//         background: #383e3e;
//         color: #9c9c9c;
//         .plan-name {
//           font-weight: bold;
//           text-transform: uppercase;
//         }
//         .plan-price {
//           span {
//             font-size: 20px;
//           }
//         }
//         .plan-collaborator-limit {
//           text-transform: capitalize;
//         }
//         &:hover {
//           cursor: pointer;
//           border-color: $plot-green;
//         }
//         &.active {
//           background: #1f4339;
//           border-color: $plot-green;
//           .plan-name,
//           .plan-price {
//             color: $plot-green;
//           }
//           .plan-collaborator-limit {
//             color: #fff;
//           }
//         }
//       }
//     }
//   }
//   .subscription-submit {
//     text-align: center;
//     padding: 10px 35px;
//     button {
//       height: 70px;
//     }
//   }
//   button {
//     background: $plot-green;
//     color: #fff;
//     &:hover {
//       color: #fff;
//       background: $plot-green-hover;
//       opacity: 0.9;
//     }
//     &:disabled {
//       background-color: grey;
//     }
//     span {
//       &:first-child {
//         text-transform: uppercase;
//       }
//       display: block;
//     }
//     .price {
//       font-weight: bold;
//       text-transform: lowercase;
//     }
//   }
// }

/*---------------------------------------------------------------
                  GlobalActivityIndicator
---------------------------------------------------------------*/
.header-meta-container .activity-indicator {
    margin: 0 15px;
    color: #535756;
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 150%;
    margin-top: 5px;
    .failed path,
    .failed circle {
        fill: $plot-red;
    }
    path,
    circle {
        fill: $text-icon;
    }
    .activity-indicator-message {
        margin-top: 1px;
    }

    @media only screen and (max-width: 1130px) {
        position: fixed;
        top: 0;
        margin: 0;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        overflow: hidden;
        box-sizing: border-box;
        justify-content: center;
        color: #fff;
        transition: height 0.3s;
        padding: 7px;
        .activity-indicator-message {
            margin-top: 0;
        }
        transform: translateY(80px);
    }
}

.max-width-85 {
    max-width: 85%;
}

.width-600px {
    width: 600px;
}
/* Animations */

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.slick-arrow {
    font-size: 38px;
}

.slick-next:before,
.slick-prev:before {
    display: none;
}

.slick-prev-angle,
.slick-next-angle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20%;

    background-color: rgba(0, 0, 0, 0.3);
    width: 3rem;
    height: 3rem;

    @media screen and (max-width: 500px) {
        width: 4rem;
        height: 4rem;
    }
}

// .slick-next {
//     right: -5rem;
// }

// .slick-prev {
//     left: -5rem;
// }

.slick-next,
.slick-prev {
    width: 3rem;
    height: 3rem;
    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.slick-dots {
    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.project-preview-modal {
    background: none !important;
    box-shadow: none !important;
}

.swiper-prev,
.swiper-next {
    position: absolute;
    top: 45vh;
}

.project-preview-modal__bottom-btn {
    display: none;
    i {
        color: #aaa;

        @media screen and (max-width: 500px) {
            font-size: 50px;
        }
    }
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}
