/* Print styling */

@media print {
    .scene-gutter {
        display: none;
    }
    .scene__voice-over,
    .scene__description {
        font-size: 0.9rem;
        word-break: break-word;
    }
    .scene__voice-over {
        position: relative;
    }

    [class*='col-sm-'] {
        float: left;
    }

    [class*='col-xs-'] {
        float: left;
    }

    .col-sm-12,
    .col-xs-12 {
        width: 100% !important;
    }

    .col-sm-11,
    .col-xs-11 {
        width: 91.66666667% !important;
    }

    .col-sm-10,
    .col-xs-10 {
        width: 83.33333333% !important;
    }

    .col-sm-9,
    .col-xs-9 {
        width: 75% !important;
    }

    .col-sm-8,
    .col-xs-8 {
        width: 66.66666667% !important;
    }

    .col-sm-7,
    .col-xs-7 {
        width: 58.33333333% !important;
    }

    .col-sm-6,
    .col-xs-6 {
        width: 50% !important;
    }

    .col-sm-5,
    .col-xs-5 {
        width: 41.66666667% !important;
    }

    .col-sm-4,
    .col-xs-4 {
        width: 33.33333333% !important;
    }

    .col-sm-3,
    .col-xs-3 {
        width: 25% !important;
    }

    .col-sm-2,
    .col-xs-2 {
        width: 16.66666667% !important;
    }

    .col-sm-1,
    .col-xs-1 {
        width: 8.33333333% !important;
    }

    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12 {
        float: left !important;
    }

    body {
        margin: 0;
        padding: 0 !important;
        min-width: 768px;
    }

    .container {
        width: auto;
        min-width: 750px;
    }

    body {
        font-size: 10px;
    }

    a[href]:after {
        content: none;
    }

    .noprint {
        display: none !important;
    }

    [contenteditable='plaintext-only']:empty:before {
        content: '' !important;
    }
}

.print .print-title-page {
    color: black;
}

.print .print-title-page h1 {
    font-weight: bolder;
    font-size: 55px;
}

.print .print-title-page .project-description {
    font-size: 24px;
}

.print .scene {
    page-break-inside: avoid;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
    margin-top: 8px;
    float: left;
    margin-bottom: 8px;
    padding: 0 8px;

    .scene-number {
        white-space: nowrap;
        font-size: 0.9rem;
        font-weight: bold;
    }
}

.three-print-layout .scene {
    width: 33.333333%;
    height: calc(100% - 20px);
}

.six-print-layout .scene {
    min-width: 33.333333%;
    max-width: 33.333333%;
    height: calc(50% - 20px);
}

.nine-print-layout .scene {
    min-width: 33.333333%;
    max-width: 33.333333%;
    height: calc(33% - 20px);
    .scene-border,
    .scene-inner-container {
        border: none;
    }
    .image-container {
        border: 2px solid rgba(0, 0, 0, 0.05);
    }
}

.one-print-layout .scene {
    width: 70%;
    height: 95%;
    align-self: center;
    .scene__voice-over,
    .scene__description {
        font-size: 1rem;
    }
}
.one-print-layout .print-row {
    justify-content: center;
}

.four-print-layout .scene {
    min-width: 50%;
    max-width: 50%;
    height: calc(50% - 20px);
    .scene-border,
    .scene-inner-container {
        border: none;
    }
    .image-container {
        border: 2px solid rgba(0, 0, 0, 0.05);
    }
}

.print .toolbar-script {
    font-size: 1.5rem;
    textarea::placeholder {
        color: transparent;
        opacity: 0;
    }
}

.print .print-script {
    display: flex;
    flex-direction: row;
    margin: 15px 0;
    white-space: pre-wrap;
    .scene-number {
        margin-right: 8px;
    }
}

.print .print-rows {
    height: 100%;
}

.print .print-row {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    height: 100%;
}
