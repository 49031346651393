.text-grey {
    color: #ccc;
}
.text-primary {
    color: rgb(10, 223, 166);
}
.text-primary-important {
    color: rgb(10, 223, 166) !important;
}
.text-primary-lt {
    color: #636bb0;
}
.text-primary-lter {
    color: #747bb9;
}
.text-primary-dk {
    color: #4b5395;
}
.text-primary-dker {
    color: #434984;
}
.text-info {
    color: #4cb6cb;
}
.text-info-lt {
    color: #60bed1;
}
.text-info-lter {
    color: #74c6d6;
}
.text-info-dk {
    color: #39adc4;
}
.text-info-dker {
    color: #339cb1;
}
.text-success {
    color: #1ab667;
}
.text-success-lt {
    color: #1dcc74;
}
.text-success-lter {
    color: #23e080;
}
.text-success-dk {
    color: #17a05a;
}
.text-success-dker {
    color: #14894e;
}
.text-warning {
    color: #fad733;
}
.text-warning-lt {
    color: #fbdc4c;
}
.text-warning-lter {
    color: #fbe165;
}
.text-warning-dk {
    color: #f9d21a;
}
.text-warning-dker {
    color: #f4ca06;
}
.text-danger {
    color: #f05050;
}
.text-danger-lt {
    color: #f26767;
}
.text-danger-lter {
    color: #f47f7f;
}
.text-danger-dk {
    color: #ee3939;
}
.text-danger-dker {
    color: #ec2121;
}
.text-dark {
    color: #5a6a7a;
}
.text-dark-lt {
    color: #657789;
}
.text-dark-lter {
    color: #718496;
}
.text-dark-dk {
    color: #4f5d6b;
}
.text-dark-dker {
    color: #44515d;
}
.text-black {
    color: #000;
}
.text-white {
    color: #fff;
}
.text-muted {
    color: #939aa0;
}
.text-light {
    color: #bbb;
}
.font-normal {
    font-weight: normal;
}
.font-lighter {
    font-weight: lighter;
}
.font-thin {
    font-weight: 300;
}
.font-bold {
    font-weight: 600;
}
.font-bolder {
    font-weight: 700;
}
.text-title {
    font-size: 32px;
}
.text-lg {
    font-size: 18px;
}
.text-md {
    font-size: 16px;
}
.text-sm {
    font-size: 13px;
}
.text-xs {
    font-size: 12px;
}
.text-xxs {
    font-size: 11px;
}

.text-italic {
    font-style: italic;
}

.text-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-u-c {
    text-transform: uppercase;
}
.text-capitalize {
    text-transform: capitalize;
}
.text-l-t {
    text-decoration: line-through;
}
.text-u-l {
    text-decoration: underline;
}
.text-active,
.active > .text,
.active > .auto .text {
    display: none !important;
}
.active > .text-active,
.active > .auto .text-active {
    display: inline-block !important;
}

.pos-rlt {
    position: relative;
}
.pos-stc {
    position: static;
}
.pos-abt {
    position: absolute;
}
.line {
    *width: 100%;
    height: 2px;
    margin: 10px 0;
    font-size: 0;
    overflow: hidden;
}
.line-xs {
    margin: 0;
}
.line-lg {
    margin-top: 15px;
    margin-bottom: 15px;
}
.line-dashed {
    border-style: dashed !important;
    background-color: transparent;
    border-width: 0;
}
.no-line {
    border-width: 0;
}
.no-border,
.no-borders {
    border-color: transparent;
    border-width: 0;
}
.no-radius {
    border-radius: 0;
}
.block {
    display: block;
}
.block.hide {
    display: none;
}
.inline {
    display: inline;
}
.none {
    display: none;
}
.pull-right-lg {
    float: right;
}
.pull-left-lg {
    float: left;
}
.pull-none {
    float: none;
}

input,
textarea {
    -webkit-user-select: text;
}

// textarea placeholder color
.text-color-ddd::-webkit-input-placeholder {
    color: #ddd;
}
.text-color-ddd:-moz-placeholder {
    /* Firefox 18- */
    color: #ddd;
}
.text-color-ddd::-moz-placeholder {
    /* Firefox 19+ */
    color: #ddd;
}
.text-color-ddd:-ms-input-placeholder {
    color: #ddd;
}
.text-color-ddd::placeholder {
    color: #ddd;
}

textarea.active {
    color: #000;
}
