#sketch-api {
	font-size: 12px;
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	overflow: unset;
	background: #888888;
	z-index: 1;

	.alert-filter-stack {
		.filter {
			min-width: 200px;
			cursor: pointer;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			padding: 5px 0 5px 0;
			text-transform: capitalize;
		}
		.filter:hover:after {
			font-family: 'sketchpad';
			//color: $bg-selected;
			content: '\e600';
			float: right;
		}
	}

	sketch-document-layout {
		background: white;
	}

	.sk-powernib {
		display: none;
	}

	.row {
		margin-left: 0;
		margin-right: 0;
	}

	sketch-document-layout {
		background: white;
	}

	sketch-AssetFinder {
		sketch-AssetFinderHeader {
			input {
				color: black;
			}
		}
	}

	sketch-Popout.toolConfig {
		min-height: 61px;
		width: 224px;
	}

	sketch-Popout sketch-PopoutContent {
		max-height: calc(100vh - 105px);
	}

	sketch-GridView {
		div.row {
			pointer-events: auto;
		}
	}

	::selection {
		background: rgba(179, 212, 252, 0.5);
	}
}

/**
 * #24: Hide the arc-timer for convenience since it interferes with ACTIVATE_SCENE
**/
arc-timer {
	display: none !important;
}

sketch-SizeTooltip {
	display: flex;
	flex-direction: row;
	align-items: center;

	span {
		display: inline-block;
		min-width: 15px;
		text-align: center;
		padding-right: 3px;

		&:nth-child(1),
		&:nth-child(3) {
			opacity: 0.5;
		}

		&:nth-child(2) {
			font-weight: bold;
		}
	}
}