/* plot-modal-dialog */
@import './variables.scss';

.plot-modal-dialog {
    display: flex;
    flex-direction: column;
}

.plot-modal-dialog .form-group {
    margin-bottom: 10px;
    border-radius: $plot-default-border-radius;
}

.plot-modal-dialog .form-control:focus {
    border-color: inherit;
    outline: none;
}

.plot-modal-dialog textarea.form-control .plot-modal-dialog input[type='text'].form-control {
    height: 42px;
}

.plot-modal-dialog .title,
.invite-dialog .title,
.project-create-dialog .title {
    color: #fff;
    font-size: 32px;
    font-weight: lighter;
    margin-bottom: 3px;
}

.plot-modal-dialog .title.new-project-title {
    margin-bottom: 15px;
}

.plot-modal-dialog .description {
    color: #fff;
    margin: 0;
}

.plot-modal-dialog .btn-submit {
    height: 42px;
    border-radius: $plot-default-border-radius;
}

.plot-modal-dialog .close {
    color: #ddd;
}

.plot-modal-dialog textarea,
.plot-modal-dialog input {
    text-align: center;
    background-color: transparent;
    color: #fff;
}

.plot-modal-dialog .content {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
}

.modal-has-subtitle + .content {
    margin-top: 30px;
}

.team-members {
    overflow-y: auto;
}

/* Start Scroll Style */
.plot-modal-dialog .members {
    scrollbar-width: none;
    -ms-overflow-style: none;

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    &.on-scrollbar {
        scrollbar-width: 8px;
        -ms-overflow-style: none;
    }

    &.on-scrollbar::-webkit-scrollbar {
        width: 8px;
    }

    &.on-scrollbar::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &.on-scrollbar::-webkit-scrollbar-track:hover {
        background-color: #acacac !important;
        width: 21px;
    }

    &.on-scrollbar::-webkit-scrollbar-thumb {
        background-color: rgba(45, 45, 45, 0.75);
        border-radius: 8px;
        border: 4px;
    }

    &.on-scrollbar::-webkit-scrollbar-button {
        display: none;
    }
}

.scenes-container {
    &.on-scrollbar {
        scrollbar-width: 12px;
    }
    &.on-scrollbar::-webkit-scrollbar-thumb {
        background-color: rgba(45, 45, 45, 0.25);
        height: 60px;
    }
    &.on-scrollbar::-webkit-scrollbar-thumb:hover,
    &.on-scrollbar::-webkit-scrollbar-track:hover {
        cursor: pointer;
        background-color: rgba(45, 45, 45, 0.75);
    }
    &.on-scrollbar::-webkit-scrollbar {
        width: 12px;
    }
}
/* End Scroll Style */

.plot-modal-dialog .members {
    margin: 20px 0;
    padding-top: 10px;
    border-top: 1px solid #666;
}

.plot-modal-dialog .members .remove-column {
    width: 20px;
}

.plot-btn-primary {
    padding: 10px 16px;
    background-color: $plot-green;
    color: white;
}

.plot-btn-primary:hover,
button.bg-primary:hover,
.plot-btn-primary:focus,
button.bg-primary:focus {
    background-color: $plot-green-hover;
    color: #fff;
}

.plot-modal-dialog .plot-btn-danger {
    background-color: $plot-red;
    color: white;
    border-radius: $plot-default-border-radius;
    height: 42px;
    outline: none;

    &:hover,
    &:focus {
        background-color: $plot-red-hover;
    }
}

button.bg-danger:hover {
    background-color: $plot-red-hover;
}

.plot-modal-dialog .plot-btn-transparent {
    background-color: transparent;
    border-radius: $plot-default-border-radius;
    height: 42px;
    outline: none;

    &:hover {
        color: white;
    }
}

.plot-modal-dialog .plot-btn-primary[disabled] {
    background-color: grey;
    color: #fff;
}

.plot-modal-dialog .plot-btn-primary:not([disabled]):focus,
.plot-modal-dialog .plot-btn-primary:not([disabled]):hover {
    opacity: 0.9;
}

.plt-btn-primary,
.plt-btn-danger {
    border-radius: $plot-default-border-radius;
    height: 42px;
    outline: none;
}
