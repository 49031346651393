.m-auto {
    margin: auto;
}

.m-xxs {
    margin: 2px 4px;
}
.m-xs {
    margin: 5px;
}
.m-sm {
    margin: 10px;
}
.m {
    margin: 15px;
}
.m-md {
    margin: 20px;
}
.m-lg {
    margin: 30px;
}
.m-xl {
    margin: 50px;
}
.m-n {
    margin: 0 !important;
}
.m-l-auto {
    margin-left: auto;
}
.m-l-none {
    margin-left: 0;
}
.m-l-xs {
    margin-left: 5px;
}
.m-l-sm {
    margin-left: 10px;
}
.m-l {
    margin-left: 15px;
}
.m-l-md {
    margin-left: 20px;
}
.m-l-lg {
    margin-left: 30px;
}
.m-l-xl {
    margin-left: 40px;
}
.m-l-n-xxs {
    margin-left: -1px;
}
.m-l-n-xs {
    margin-left: -5px;
}
.m-l-n-sm {
    margin-left: -10px;
}
.m-l-n {
    margin-left: -15px;
}
.m-l-n-md {
    margin-left: -20px;
}
.m-l-n-lg {
    margin-left: -30px;
}
.m-l-n-xl {
    margin-left: -40px;
}
.m-t-none {
    margin-top: 0;
}
.m-t-xxs {
    margin-top: 1px;
}
.m-t-xs {
    margin-top: 5px;
}
.m-t-sm {
    margin-top: 10px;
}
.m-t {
    margin-top: 15px;
}
.m-t-md {
    margin-top: 20px;
}
.m-t-lg {
    margin-top: 30px;
}
.m-t-xl {
    margin-top: 40px;
}
.m-t-n-xxs {
    margin-top: -1px;
}
.m-t-n-xs {
    margin-top: -5px;
}
.m-t-n-sm {
    margin-top: -10px;
}
.m-t-n {
    margin-top: -15px;
}
.m-t-n-md {
    margin-top: -20px;
}
.m-t-n-lg {
    margin-top: -30px;
}
.m-t-n-xl {
    margin-top: -40px;
}
.m-r-auto {
    margin-right: auto;
}
.m-r-none {
    margin-right: 0;
}
.m-r-xxs {
    margin-right: 1px;
}
.m-r-xs {
    margin-right: 5px;
}
.m-r-sm {
    margin-right: 10px;
}
.m-r {
    margin-right: 15px;
}
.m-r-md {
    margin-right: 20px;
}
.m-r-lg {
    margin-right: 30px;
}
.m-r-xl {
    margin-right: 40px;
}
.m-r-n-xxs {
    margin-right: -1px;
}
.m-r-n-xs {
    margin-right: -5px;
}
.m-r-n-sm {
    margin-right: -10px;
}
.m-r-n {
    margin-right: -15px;
}
.m-r-n-md {
    margin-right: -20px;
}
.m-r-n-lg {
    margin-right: -30px;
}
.m-r-n-xl {
    margin-right: -40px;
}
.m-b-none {
    margin-bottom: 0;
}
.m-b-xxs {
    margin-bottom: 1px;
}
.m-b-xs {
    margin-bottom: 5px;
}
.m-b-sm {
    margin-bottom: 10px;
}
.m-b {
    margin-bottom: 15px !important;
}
.m-b-md {
    margin-bottom: 20px;
}
.m-b-lg {
    margin-bottom: 30px !important;
}
.m-b-xl {
    margin-bottom: 40px;
}
.m-b-xxl {
    margin-bottom: 60px;
}
.m-b-n-xxs {
    margin-bottom: -1px;
}
.m-b-n-xs {
    margin-bottom: -5px;
}
.m-b-n-sm {
    margin-bottom: -10px;
}
.m-b-n {
    margin-bottom: -15px;
}
.m-b-n-md {
    margin-bottom: -20px;
}
.m-b-n-lg {
    margin-bottom: -30px;
}
.m-b-n-xl {
    margin-bottom: -40px;
}

.p-xxs {
    padding: 2px 4px;
}
.p-xs {
    padding: 5px;
}
.p-sm {
    padding: 10px;
}
.p {
    padding: 15px;
}
.p-md {
    padding: 20px;
}
.p-lg {
    padding: 30px;
}
.p-xl {
    padding: 50px;
}
.p-n {
    padding: 0 !important;
}
.p-l-none {
    padding-left: 0;
}
.p-l-xs {
    padding-left: 5px;
}
.p-l-sm {
    padding-left: 10px;
}
.p-l {
    padding-left: 15px;
}
.p-l-md {
    padding-left: 20px;
}
.p-l-lg {
    padding-left: 30px;
}
.p-l-xl {
    padding-left: 40px;
}
.p-l-n-xxs {
    padding-left: -1px;
}
.p-l-n-xs {
    padding-left: -5px;
}
.p-l-n-sm {
    padding-left: -10px;
}
.p-l-n {
    padding-left: -15px;
}
.p-l-n-md {
    padding-left: -20px;
}
.p-l-n-lg {
    padding-left: -30px;
}
.p-l-n-xl {
    padding-left: -40px;
}
.p-t-none {
    padding-top: 0;
}
.p-t-xxs {
    padding-top: 1px;
}
.p-t-xs {
    padding-top: 5px;
}
.p-t-sm {
    padding-top: 10px;
}
.p-t {
    padding-top: 15px;
}
.p-t-md {
    padding-top: 20px;
}
.p-t-lg {
    padding-top: 30px;
}
.p-t-xl {
    padding-top: 40px;
}
.p-t-n-xxs {
    padding-top: -1px;
}
.p-t-n-xs {
    padding-top: -5px;
}
.p-t-n-sm {
    padding-top: -10px;
}
.p-t-n {
    padding-top: -15px;
}
.p-t-n-md {
    padding-top: -20px;
}
.p-t-n-lg {
    padding-top: -30px;
}
.p-t-n-xl {
    padding-top: -40px;
}
.p-r-none {
    padding-right: 0;
}
.p-r-xxs {
    padding-right: 1px;
}
.p-r-xs {
    padding-right: 5px;
}
.p-r-sm {
    padding-right: 10px;
}
.p-r {
    padding-right: 15px;
}
.p-r-md {
    padding-right: 20px;
}
.p-r-lg {
    padding-right: 30px;
}
.p-r-xl {
    padding-right: 40px;
}
.p-r-n-xxs {
    padding-right: -1px;
}
.p-r-n-xs {
    padding-right: -5px;
}
.p-r-n-sm {
    padding-right: -10px;
}
.p-r-n {
    padding-right: -15px;
}
.p-r-n-md {
    padding-right: -20px;
}
.p-r-n-lg {
    padding-right: -30px;
}
.p-r-n-xl {
    padding-right: -40px;
}
.p-b-none {
    padding-bottom: 0;
}
.p-b-xxs {
    padding-bottom: 1px;
}
.p-b-xs {
    padding-bottom: 5px;
}
.p-b-sm {
    padding-bottom: 10px;
}
.p-b {
    padding-bottom: 15px;
}
.p-b-md {
    padding-bottom: 20px;
}
.p-b-lg {
    padding-bottom: 30px;
}
.p-b-xl {
    padding-bottom: 40px;
}
.p-b-n-xxs {
    padding-bottom: -1px;
}
.p-b-n-xs {
    padding-bottom: -5px;
}
.p-b-n-sm {
    padding-bottom: -10px;
}
.p-b-n {
    padding-bottom: -15px;
}
.p-b-n-md {
    padding-bottom: -20px;
}
.p-b-n-lg {
    padding-bottom: -30px;
}
.p-b-n-xl {
    padding-bottom: -40px;
}
