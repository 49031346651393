@import './variables.scss';

.dialog-modal .icon > span {
  color: rgb(10, 223, 166);
}

.dialog-modal .form-group {
  margin-bottom: 10px;
  border-radius: $plot-default-border-radius;
}

.dialog-modal .form-control:focus {
  border-color: inherit;
  outline: none;
}

.dialog-modal input[type='text'].form-control,
.dialog-modal textarea.form-control {
  height: 42px;
}

.dialog-modal .title {
  font-size: 22px;
  font-weight: 400;
}

.dialog-modal .description {
  color: #fff;
}

.dialog-modal .btn-submit {
  height: 42px;
  border-radius: $plot-default-border-radius;
}

.dialog-modal .close {
  color: #ddd;
}

.dialog-modal textarea,
.dialog-modal input {
  text-align: center;
  background-color: transparent !important;
  color: #fff !important;
}

.dialog-modal .content {
  width: 70%;
  margin: 0 auto;
}

.dialog-modal__auto-resize-textarea {
  border: 1px solid #666;
}
