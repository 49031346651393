@import './variables.scss';

.profile {
    input {
        text-align: left !important;
    }
    .plot-btn-primary,
    .plot-btn-danger {
        padding: 13px 20px;
        height: 50px !important;
    }
}

.profile .profile__form-row .profile__label i {
    line-height: 34px;
}

.profile__stripe-label {
    height: 34px;
    display: inline-block;
}

.profile__upgrade-error {
    margin-left: 115px;
    text-transform: none;
}

.profile__cancel-form-message {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #444;
}

.profile__change-confirmation {
    border-color: #333;
}

.plot-modal-dialog select {
    background-color: #1f2725;
    color: #fff;
    display: block;
    width: 100%;
    height: 48px;
    font-size: 14px;
    line-height: 1.42857143;
    border: 2px solid #686f75;
    border-radius: $plot-default-border-radius;

    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    appearance: none;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%235e656a%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat, repeat;
    background-position: left 0.4em top 50%, 0 0;
    background-size: 0.55em auto, 100%;
}

.coupon .profile__row-error input::placeholder {
    color: #f05050;
}

.coupon__apply-btn {
    &[disabled] {
        background-color: #eee;
        color: #6b7379;
    }
    background-color: #0adfa6;
    color: white;
    border-radius: $plot-default-border-radius;
    outline: none;
    text-align: center;
    width: 40%;
    margin: 3px 0 3px 10px;
}

.profile__row {
    position: relative;
    display: flex;
    border-radius: 4px;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background: transparent;
    padding: 13px 20px;
    color: white;
}

.profile__row.text {
    padding: 0;
}

.profile__row-btn {
    border: 2px solid #686f75;
    background-color: transparent;
    border-radius: 4px;
    &:hover:not(:disabled) {
        border: 2px solid #a0a7ae;
        background-color: #a0a7ae;
        color: white;
    }
    &:hover:not(:disabled) .profile__label {
        color: white;
    }
    &:not(:disabled) .profile__label {
        color: #686f75;
    }
}

.profile__row-btn:disabled,
.profile__row-btn[disabled] {
    border: none;
    padding: 15px 22px;
    background-color: #2e3434;
    color: #6a7376;
}

.profile__label {
    right: 0;
    bottom: 0;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 0.7em;
    position: absolute;
    text-align: right;
    color: #686f75;
    text-transform: uppercase;
    font-weight: bold;
}

.profile___label {
    width: 100%;
}

.profile__value {
    width: 100%;
    outline: none;
    margin: 0;
    padding: 13px 20px;
    border: 2px solid #686f75;
    border-radius: 4px;
    text-align: left;
    &:focus,
    &.StripeElement--focus {
        border-color: $plot-green;
        background-color: #1a5444;
    }
    &:focus + label,
    &.StripeElement--focus + label {
        color: white;
    }

    &.invalid {
        background-color: #a73838;
        color: #fff;
        border-radius: $plot-default-border-radius;
        & + label {
            color: #f05050 !important;
        }
    }
}

.StripeElement--invalid {
    background-color: #a73838;
    color: #fff;
    border-radius: $plot-default-border-radius;
    & + label {
        color: #f05050 !important;
    }
}

.plot-modal-dialog select:focus {
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%230ADFA6%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E);
}

.profile__row-warning {
    background-color: #6d6429;
    color: #efd02f;
    border-radius: $plot-default-border-radius;

    .profile__label {
        color: #efd02f;
    }
    .profile__value {
        border: 2px solid #efd02f;
    }
    .profile__value:focus {
        border-color: #efd02f;
        background-color: #6d6429;
    }
    .profile__value:focus + label {
        color: #efd02f;
    }

    &:hover:not(:disabled) {
        background-color: #9c924d;
    }
    &:hover:not(:disabled) + label {
        color: #efd02f;
    }
    &:not(:disabled) .profile__label {
        color: #efd02f;
    }
}

.profile__row-error {
    background-color: #a73838;
    color: #f05050;
    border-radius: $plot-default-border-radius;
    label {
        color: #f05050 !important;
    }
    .profile__label {
        color: #f05050;
    }
    .profile__value {
        border: 2px solid #f05050;
    }
    .profile__value:focus {
        border-color: #f05050;
        background-color: #a73838;
    }
    .profile__value:focus + label {
        color: #f05050;
    }

    &:hover:not(:disabled) {
        background-color: #a73838;
    }
    &:hover:not(:disabled) + label {
        color: #f05050;
    }
    &:not(:disabled) .profile__label {
        color: #f05050;
    }
}

.plot-modal-dialog.past-due {
    .card-error {
        background-color: #f05050;
        color: #1f2725;
        display: inline-block;
        padding: 3px 8px;
    }
}

.account-inline-edit-field {
}

// Team Plan
.team-plan-subscription {
    width: 50%;
    background-color: #2e3434;
    color: white;
    border-radius: 4px;
    outline: none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    .custom-loader {
        position: unset;
    }
    &.modified {
        background-color: $plot-green;
        cursor: pointer;
        &:hover,
        &:focus {
            background-color: $plot-green-hover;
        }
        .team-plan-subscription__note {
            color: white;
        }
    }
    p {
        margin: 0;
        font-weight: bold;
    }
    .team-plan-subscription__price {
        font-size: 24px;
        font-weight: 700;
    }
    .team-plan-subscription__note {
        font-size: 12px;
        color: $text-icon;
        font-weight: 600;
        margin-left: 4px;
    }
}

.team-plan-new-member-added {
    color: $plot-green;
    text-align: center;
}

.profile__Team-row-btn {
    position: relative;
    border-radius: 4px;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background: transparent;
    padding: 13px 20px;
    color: white;
}

.error {
    border: 2px solid $plot-red;
}
