.avatar {
  position: relative;
  display: block;
  border-radius: 500px;
  white-space: nowrap;
}
.avatar img {
  border-radius: 500px;
  width: 100%;
}
.avatar i {
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
}
.avatar i.md {
  width: 12px;
  height: 12px;
  margin: 1px;
}
.avatar i.sm {
  margin: -1px;
}
.avatar i.right {
  left: auto;
  right: 0;
}
.avatar i.bottom {
  left: auto;
  top: auto;
  bottom: 0;
  right: 0;
}
.avatar i.on {
  background-color: #1ab667;
}
.avatar i.off {
  background-color: #939aa0;
}
.avatar i.busy {
  background-color: #f05050;
}
.avatar i.away {
  background-color: #fad733;
}
.thumb-lg {
  width: 128px;
  display: inline-block;
}
.thumb-md {
  width: 64px;
  display: inline-block;
}
.thumb-sm {
  width: 40px;
  display: inline-block;
}
.thumb-xs {
  width: 30px;
  display: inline-block;
}
.thumb-wrapper {
  padding: 2px;
  border: 1px solid #dbe2e7;
}
.thumb img,
.thumb-xs img,
.thumb-sm img,
.thumb-md img,
.thumb-lg img,
.thumb-btn img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
