@import './variables.scss';

.signup {
  background-color: rgb(31, 39, 37);
}

.signup .plot-btn {
  border-radius: $plot-default-border-radius;
  height: 42px;
  outline: none;
}

.signup .plot-btn:focus,
.signup .plot-btn:hover {
  opacity: 0.9;
  outline: none;
}

.signup .plot-btn-facebook {
  font-size: 15px;
  background-color: #4264aa;
  color: #fff;
  width: 350px;
}

.signup .plot-btn-google {
  background-color: #db4437;
  color: #fff;
  width: 350px;
}

.signup .btn:focus {
  outline: none;
}

.signup__title {
  color: #fff;
  font-size: 32px;
  padding: 0px 20px;
  font-weight: lighter;
  margin-bottom: 5px;
}

.signup__desc {
  padding: 0 75px;
}

.signup__note {
  color: #fff;
  font-weight: lighter;
  margin-bottom: 15px;
}

.registration {
  form {
    width: 200px;
    .form-group {
      margin-bottom: 10px;
    }
  }
  input {
    text-align: center;
    background-color: transparent;
    color: #fff;
    &:focus {
      border-color: inherit;
    }
  }
  a {
    color: $plot-green;
  }

  .checkbox {
    input[type='checkbox'] {
      display: block;
      margin: 2px 0;
      font-size: medium;
    }
    label {
      min-width: 125%;
      text-align: left;
    }
  }

  .policyTermErrorMessage {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }
}
