$header-height: 60px;

.paint-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(42, 45, 51, 0.8);
  z-index: 9;
  .paint-loader__saving {
    z-index: 9;
    span {
      color: #eeeeee;
    }
    span[class*='l-'] {
      background-color: #eeeeee;
    }
  }
}

.paint-canvas__header {
  position: absolute;
  top: 80px;
  height: $header-height;
  right: 23px;
  width: 200px;
  z-index: 2;
}

.paint-canvas__body {
  position: absolute;
  top: 78px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  .sketch-docscrollarea { height: 100%; }

}

.paint-canvas .literally {
  height: 100%;
}

.paint-overlay {
  background: #fff;
  opacity: 0.8;
}
