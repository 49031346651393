.bg-primary {
    background-color: rgb(10, 223, 166);
    color: #fff;
}
.bg-light {
    background-color: #eee;
    color: #6b7379 !important;
}
.bg-dark {
    background-color: #999;
    color: #fff !important;
}
.bg-primary.lt,
.bg-primary .lt {
    background-color: #666dad;
}
.bg-primary.lter,
.bg-primary .lter {
    background-color: #797fb4;
}
.bg-primary.dk,
.bg-primary .dk {
    background-color: #495098;
}
.bg-primary.dker,
.bg-primary .dker {
    background-color: #3e4589;
}
.bg-primary.bg,
.bg-primary .bg {
    background-color: #545ca6;
}
.bg-primary-ltest {
    background-color: #ecf6fb;
}
.bg-primary a {
    color: #fcfcfd;
}
.bg-primary a:hover {
    color: #fff;
}
.bg-primary a.list-group-item:hover,
.bg-primary a.list-group-item:focus {
    background-color: inherit;
}
.bg-primary .nav > li:hover > a,
.bg-primary .nav > li:focus > a,
.bg-primary .nav > li:active > a,
.bg-primary .nav > li.active > a {
    color: #fff;
    background-color: #6068b3;
}
.bg-primary .nav > li > a {
    color: #ebecf5;
}
.bg-primary .nav > li > a:hover,
.bg-primary .nav > li > a:focus {
    background-color: #6068b3;
}
.bg-primary .nav .open > a {
    background-color: #6068b3;
}
.bg-primary .caret {
    border-top-color: #dadcec;
    border-bottom-color: #dadcec;
}
.bg-primary.navbar .nav > li.active > a {
    color: #fff;
    background-color: #495098;
}
.bg-primary .open > a,
.bg-primary .open > a:hover,
.bg-primary .open > a:focus {
    color: #fff;
}
.bg-primary .text-muted {
    color: #b8bbdb !important;
}
.bg-primary .text-lt {
    color: #ffffff !important;
}
.bg-primary .icon-muted {
    color: #b8bbdb !important;
}
.bg-primary.auto .list-group-item,
.bg-primary .auto .list-group-item {
    border-color: #4b5395 !important;
    background-color: transparent;
}
.bg-primary.auto .list-group-item:hover,
.bg-primary.auto .list-group-item:focus,
.bg-primary.auto .list-group-item:active,
.bg-primary.auto .list-group-item.active,
.bg-primary .auto .list-group-item:hover,
.bg-primary .auto .list-group-item:focus,
.bg-primary .auto .list-group-item:active,
.bg-primary .auto .list-group-item.active {
    background-color: #495098 !important;
}
.bg-success {
    background-color: rgb(10, 223, 166);
    color: #fff;
}
.bg-success.lt,
.bg-success .lt {
    background-color: #20c974;
}
.bg-success.lter,
.bg-success .lter {
    background-color: #2ad980;
}
.bg-success.dk,
.bg-success .dk {
    background-color: #15a25a;
}
.bg-success.dker,
.bg-success .dker {
    background-color: #108d4e;
}
.bg-success.bg,
.bg-success .bg {
    background-color: rgb(10, 223, 166);
}
.bg-success-ltest {
    background-color: #f1ffed;
}
.bg-success a {
    color: #d6f9e7;
}
.bg-success a:hover {
    color: #fff;
}
.bg-success a.list-group-item:hover,
.bg-success a.list-group-item:focus {
    background-color: inherit;
}
.bg-success .nav > li:hover > a,
.bg-success .nav > li:focus > a,
.bg-success .nav > li:active > a,
.bg-success .nav > li.active > a {
    color: #fff;
    background-color: #1acf74;
}
.bg-success .nav > li > a {
    color: #c0f6da;
}
.bg-success .nav > li > a:hover,
.bg-success .nav > li > a:focus {
    background-color: #1acf74;
}
.bg-success .nav .open > a {
    background-color: #1acf74;
}
.bg-success .caret {
    border-top-color: #a9f3ce;
    border-bottom-color: #a9f3ce;
}
.bg-success.navbar .nav > li.active > a {
    color: #fff;
    background-color: #15a25a;
}
.bg-success .open > a,
.bg-success .open > a:hover,
.bg-success .open > a:focus {
    color: #fff;
}
.bg-success .text-muted {
    color: #7decb4 !important;
}
.bg-success .text-lt {
    color: #ffffff !important;
}
.bg-success .icon-muted {
    color: #7decb4 !important;
}
.bg-success.auto .list-group-item,
.bg-success .auto .list-group-item {
    border-color: #17a05a !important;
    background-color: transparent;
}
.bg-success.auto .list-group-item:hover,
.bg-success.auto .list-group-item:focus,
.bg-success.auto .list-group-item:active,
.bg-success.auto .list-group-item.active,
.bg-success .auto .list-group-item:hover,
.bg-success .auto .list-group-item:focus,
.bg-success .auto .list-group-item:active,
.bg-success .auto .list-group-item.active {
    background-color: #15a25a !important;
}
.bg-info {
    background-color: #4cb6cb;
    color: #eaf6f9;
}
.bg-info.lt,
.bg-info .lt {
    background-color: #62bcce;
}
.bg-info.lter,
.bg-info .lter {
    background-color: #78c3d2;
}
.bg-info.dk,
.bg-info .dk {
    background-color: #36b0c8;
}
.bg-info.dker,
.bg-info .dker {
    background-color: #2ea0b6;
}
.bg-info.bg,
.bg-info .bg {
    background-color: #4cb6cb;
}
.bg-info-ltest {
    background-color: #ecfcff;
}
.bg-info a {
    color: #ffffff;
}
.bg-info a:hover {
    color: #fff;
}
.bg-info a.list-group-item:hover,
.bg-info a.list-group-item:focus {
    background-color: inherit;
}
.bg-info .nav > li:hover > a,
.bg-info .nav > li:focus > a,
.bg-info .nav > li:active > a,
.bg-info .nav > li.active > a {
    color: #fff;
    background-color: #5dc0d3;
}
.bg-info .nav > li > a {
    color: #f2f2f2;
}
.bg-info .nav > li > a:hover,
.bg-info .nav > li > a:focus {
    background-color: #5dc0d3;
}
.bg-info .nav .open > a {
    background-color: #5dc0d3;
}
.bg-info .caret {
    border-top-color: #eaf6f9;
    border-bottom-color: #eaf6f9;
}
.bg-info.navbar .nav > li.active > a {
    color: #fff;
    background-color: #36b0c8;
}
.bg-info .open > a,
.bg-info .open > a:hover,
.bg-info .open > a:focus {
    color: #fff;
}
.bg-info .text-muted {
    color: #c3e6ed !important;
}
.bg-info .text-lt {
    color: #ffffff !important;
}
.bg-info .icon-muted {
    color: #c3e6ed !important;
}
.bg-info.auto .list-group-item,
.bg-info .auto .list-group-item {
    border-color: #39adc4 !important;
    background-color: transparent;
}
.bg-info.auto .list-group-item:hover,
.bg-info.auto .list-group-item:focus,
.bg-info.auto .list-group-item:active,
.bg-info.auto .list-group-item.active,
.bg-info .auto .list-group-item:hover,
.bg-info .auto .list-group-item:focus,
.bg-info .auto .list-group-item:active,
.bg-info .auto .list-group-item.active {
    background-color: #36b0c8 !important;
}
.bg-warning {
    background-color: #fad733;
    color: #fffefa;
}
.bg-warning.lt,
.bg-warning .lt {
    background-color: #f8da4e;
}
.bg-warning.lter,
.bg-warning .lter {
    background-color: #f7de69;
}
.bg-warning.dk,
.bg-warning .dk {
    background-color: #fcd417;
}
.bg-warning.dker,
.bg-warning .dker {
    background-color: #face00;
}
.bg-warning.bg,
.bg-warning .bg {
    background-color: #fad733;
}
.bg-warning-ltest {
    background-color: #fffee6;
}
.bg-warning a {
    color: #ffffff;
}
.bg-warning a:hover {
    color: #fff;
}
.bg-warning a.list-group-item:hover,
.bg-warning a.list-group-item:focus {
    background-color: inherit;
}
.bg-warning .nav > li:hover > a,
.bg-warning .nav > li:focus > a,
.bg-warning .nav > li:active > a,
.bg-warning .nav > li.active > a {
    color: #fff;
    background-color: #fddd4a;
}
.bg-warning .nav > li > a {
    color: #f2f2f2;
}
.bg-warning .nav > li > a:hover,
.bg-warning .nav > li > a:focus {
    background-color: #fddd4a;
}
.bg-warning .nav .open > a {
    background-color: #fddd4a;
}
.bg-warning .caret {
    border-top-color: #fffefa;
    border-bottom-color: #fffefa;
}
.bg-warning.navbar .nav > li.active > a {
    color: #fff;
    background-color: #fcd417;
}
.bg-warning .open > a,
.bg-warning .open > a:hover,
.bg-warning .open > a:focus {
    color: #fff;
}
.bg-warning .text-muted {
    color: #fef4c8 !important;
}
.bg-warning .text-lt {
    color: #ffffff !important;
}
.bg-warning .icon-muted {
    color: #fef4c8 !important;
}
.bg-warning.auto .list-group-item,
.bg-warning .auto .list-group-item {
    border-color: #f9d21a !important;
    background-color: transparent;
}
.bg-warning.auto .list-group-item:hover,
.bg-warning.auto .list-group-item:focus,
.bg-warning.auto .list-group-item:active,
.bg-warning.auto .list-group-item.active,
.bg-warning .auto .list-group-item:hover,
.bg-warning .auto .list-group-item:focus,
.bg-warning .auto .list-group-item:active,
.bg-warning .auto .list-group-item.active {
    background-color: #fcd417 !important;
}
.bg-danger {
    background-color: #f05050;
    color: #ffffff;
}
.bg-danger.lt,
.bg-danger .lt {
    background-color: #f06a6a;
}
.bg-danger.lter,
.bg-danger .lter {
    background-color: #f18282;
}
.bg-danger.dk,
.bg-danger .dk {
    background-color: #f13636;
}
.bg-danger.dker,
.bg-danger .dker {
    background-color: #f21b1b;
}
.bg-danger.bg,
.bg-danger .bg {
    background-color: #f05050;
}
.bg-danger-ltest {
    background-color: #fbedec;
}
.bg-danger a {
    color: #ffffff;
}
.bg-danger a:hover {
    color: #fff;
}
.bg-danger a.list-group-item:hover,
.bg-danger a.list-group-item:focus {
    background-color: inherit;
}
.bg-danger .nav > li:hover > a,
.bg-danger .nav > li:focus > a,
.bg-danger .nav > li:active > a,
.bg-danger .nav > li.active > a {
    color: #fff;
    background-color: #f46565;
}
.bg-danger .nav > li > a {
    color: #f2f2f2;
}
.bg-danger .nav > li > a:hover,
.bg-danger .nav > li > a:focus {
    background-color: #f46565;
}
.bg-danger .nav .open > a {
    background-color: #f46565;
}
.bg-danger .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
}
.bg-danger.navbar .nav > li.active > a {
    color: #fff;
    background-color: #f13636;
}
.bg-danger .open > a,
.bg-danger .open > a:hover,
.bg-danger .open > a:focus {
    color: #fff;
}
.bg-danger .text-muted {
    color: #e6e6e6 !important;
}
.bg-danger .text-lt {
    color: #ffffff !important;
}
.bg-danger .icon-muted {
    color: #e6e6e6 !important;
}
.bg-danger.auto .list-group-item,
.bg-danger .auto .list-group-item {
    border-color: #ee3939 !important;
    background-color: transparent;
}
.bg-danger.auto .list-group-item:hover,
.bg-danger.auto .list-group-item:focus,
.bg-danger.auto .list-group-item:active,
.bg-danger.auto .list-group-item.active,
.bg-danger .auto .list-group-item:hover,
.bg-danger .auto .list-group-item:focus,
.bg-danger .auto .list-group-item:active,
.bg-danger .auto .list-group-item.active {
    background-color: #f13636 !important;
}
.bg-white {
    background-color: #fff;
    color: #6b7379;
}
.bg-white a {
    color: #545a5f;
}
.bg-white a:hover {
    color: #3c4144;
}
.bg-white .text-muted {
    color: #939aa0 !important;
}
.bg-white-only {
    background-color: #fff;
}
.bg-empty {
    background-color: transparent;
}
a.bg-light:hover,
a.bg-light:focus {
    color: #545a5f;
}
