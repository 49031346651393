$text-dark: #5a6a7a;
$text-black: #000;
$default-blue-border-color: #005cbf;

$text-icon: #9fa3ae;
$plot-green: #0adfa6;
$plot-green-hover: #0aa57c;
$plot-black: #1f2725;
$plot-red: #f05050;
$plot-red-hover: #c73737;
$plot-menu-hover-grey: #646b73;

$scene-top-padding: 30px;

$default-toolbar-scene-border: 2px solid #eee;
$plot-default-border-radius: 4px;
$plot-default-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

$plot-scene-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
