.animated {
  -webkit-animation-fill-mode:both;
  -moz-animation-fill-mode:both;
  -ms-animation-fill-mode:both;
  -o-animation-fill-mode:both;
  animation-fill-mode:both;
  -webkit-animation-duration:0.5s;
  -moz-animation-duration:0.5s;
  -ms-animation-duration:0.5s;
  -o-animation-duration:0.5s;
  animation-duration:0.5s;
}

.animated-fast {
  -webkit-animation-fill-mode:both;
  -moz-animation-fill-mode:both;
  -ms-animation-fill-mode:both;
  -o-animation-fill-mode:both;
  animation-fill-mode:both;
  -webkit-animation-duration:0.25s;
  -moz-animation-duration:0.25s;
  -ms-animation-duration:0.25s;
  -o-animation-duration:0.25s;
  animation-duration:0.25s;
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}  100% {opacity: 1;}
}

@-moz-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-o-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  -moz-animation-name: fadeInUpBig;
  -o-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}
@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  -moz-animation-name: fadeInDownBig;
  -o-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}
@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  -moz-animation-name: fadeInLeftBig;
  -o-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}
@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  -moz-animation-name: fadeInRightBig;
  -o-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}
@-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@-moz-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@-o-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  -o-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
}
@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
}
@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
}

@-moz-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
}

@-o-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  -moz-animation-name: fadeOutDown;
  -o-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}
@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
}

@-moz-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
}

@-o-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  -moz-animation-name: fadeOutLeft;
  -o-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
}

@-moz-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
}

@-o-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  -moz-animation-name: fadeOutRight;
  -o-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}
@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}

@-o-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  -moz-animation-name: fadeOutUpBig;
  -o-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}
@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}

@-moz-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}

@-o-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  -moz-animation-name: fadeOutDownBig;
  -o-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}
@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}

@-moz-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}

@-o-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  -moz-animation-name: fadeOutLeftBig;
  -o-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}
@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}
@-moz-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}
@-o-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}
@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  -moz-animation-name: fadeOutRightBig;
  -o-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}




@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateX(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateX(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}
@-moz-keyframes flipInX {
    0% {
        -moz-transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }

    40% {
        -moz-transform: perspective(400px) rotateX(-10deg);
    }

    70% {
        -moz-transform: perspective(400px) rotateX(10deg);
    }

    100% {
        -moz-transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}
@-o-keyframes flipInX {
    0% {
        -o-transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }

    40% {
        -o-transform: perspective(400px) rotateX(-10deg);
    }

    70% {
        -o-transform: perspective(400px) rotateX(10deg);
    }

    100% {
        -o-transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}
@keyframes flipInX {
    0% {
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotateX(-10deg);
    }

    70% {
        transform: perspective(400px) rotateX(10deg);
    }

    100% {
        transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}

.animated.flipInX {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInX;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInX;
  backface-visibility: visible !important;
  animation-name: flipInX;
}

@-webkit-keyframes flipInY {
    0% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateY(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateY(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        opacity: 1;
    }
}
@-moz-keyframes flipInY {
    0% {
        -moz-transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }

    40% {
        -moz-transform: perspective(400px) rotateY(-10deg);
    }

    70% {
        -moz-transform: perspective(400px) rotateY(10deg);
    }

    100% {
        -moz-transform: perspective(400px) rotateY(0deg);
        opacity: 1;
    }
}
@-o-keyframes flipInY {
    0% {
        -o-transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }

    40% {
        -o-transform: perspective(400px) rotateY(-10deg);
    }

    70% {
        -o-transform: perspective(400px) rotateY(10deg);
    }

    100% {
        -o-transform: perspective(400px) rotateY(0deg);
        opacity: 1;
    }
}
@keyframes flipInY {
    0% {
        transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotateY(-10deg);
    }

    70% {
        transform: perspective(400px) rotateY(10deg);
    }

    100% {
        transform: perspective(400px) rotateY(0deg);
        opacity: 1;
    }
}

.animated.flipInY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInY;
  backface-visibility: visible !important;
  animation-name: flipInY;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.98, .98, .98);
    transform: scale3d(.98, .98, .98);
  }

  80% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.98, .98, .98);
    transform: scale3d(.98, .98, .98);
  }

  80% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@keyframes fadeinWithOpacity {
  from {opacity: 0;}
  to {opacity: .8;}
}

/* Firefox < 16 */

@-moz-keyframes fadeinWithOpacity {
  from {opacity: 0;}
  to {opacity: .8;}
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadeinWithOpacity {
  from {opacity: 0;}
  to {opacity: .8;}
}

/* Internet Explorer */

@-ms-keyframes fadeinWithOpacity {
  from {opacity: 0;}
  to {opacity: .8;}
}

/* Opera < 12.1 */

@-o-keyframes fadeinWithOpacity {
  from {opacity: 0;}
  to {opacity: .8;}
}

@keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}


/* Firefox < 16 */

@-moz-keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}


/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}


/* Internet Explorer */

@-ms-keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}


/* Opera < 12.1 */

@-o-keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}